import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { httpClient } from '../HttpClientV1/HttpClient'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    promocode:null,
  },
  mutations: {
    updatePromocode(state, data){
      state.promocode = data
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    reg_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    applyPromocode(ctx, data){
      return new Promise((resolve, reject) => {
        httpClient.post('/api/promo/use', data)
        .then(response => {
            if(response.data.status == 'success'){
              ctx.commit('updatePromocode', data)
              localStorage.setItem('promocode', data)
              resolve(response.data)
            }
            else{
              reject(response.data)
            }
        })
        .catch(err => {
          reject(err.response.data)
        })
      })
    },
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        httpClient({url: '/api/users/login/', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.user.token
          const user = resp.data.user.username
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = 'Token '+ token
          commit('auth_success', token, user)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    login_local({commit}, user){
      
      localStorage.setItem('token', user)
      commit('auth_success', user, user)
    },
    register({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        httpClient({url: '/api/users/', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.user.token
          const user = resp.data.user.username
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = 'Token '+ token
          commit('reg_success', token, user)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
     },
     logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
     }
    },
  getters : {
  getToken: state => state.token,
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  },
})