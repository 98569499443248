import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import routes from './router'
import store from './store'

import i18n from "./i18n";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { VueSpinners } from '@saeris/vue-spinners'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css';
import './assets/css/app.css'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
if(i18n.locale == 'ru'){
  localize("ru", ru);
}
else{
  localize("en", en);
}

Vue.use(VueSpinners)
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
const router = new VueRouter({
  mode:"history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach((to, from, next) => {
  if(to.query.pc){   
    localStorage.setItem('pc',to.query.pc)
  }
  if(to.query.ref){   
    localStorage.setItem('referral_code',to.query.ref)
  }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    localStorage.setItem('utm_source', to.query.utm_source)
    localStorage.setItem('utm_medium', to.query.utm_medium)
    localStorage.setItem('utm_campaign', to.query.utm_campaign)
    localStorage.setItem('utm_content', to.query.utm_content)
    next('/user/login') 
    return
  } else {
    next() 
    return
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
