<template>
  <b-container fluid class="empty">
    <div>
      <!-- As a link -->
      <b-navbar variant="faded" type="light" class="mb-4">
        <b-navbar-brand to="/"><b-img-lazy left v-bind="mainProps" :src="loadLogo"></b-img-lazy></b-navbar-brand>
      </b-navbar>
    </div>
    <b-container style="min-height:70vh">
    <router-view />
    </b-container>
        <b-container class="mb-5 mt-5">
 <b-row
      class="d-flex justify-content-center align-items-center align-content-center pl-3 pr-3 animate__animated animate__backInUp"
    >
    <a href="https://gctransfer.co/" target="_blank" class="pr-3 nav-link">gctransfer.co</a>
    <a href="https://t.me/gctransfer_support_bot" target="_blank" class="nav-link">
    
    <img src="@/assets/img/telegram.svg" width="20" style="position: relative;top: -1px;"/> support</a>
 </b-row>

    </b-container>
  </b-container>
</template>
<script>
export default {
    data() {
      return {
        mainProps: {
          center: false,
          fluidGrow: false,
          blank: true,
          blankColor: '#f0f7fd',
          width: 200,
          height:70,
          class: 'my-0'
        }
      }
    },
    computed:{
        loadLogo(){
            return process.env.VUE_APP_API_SERVER + "static/img/logoGC.png"
        }
    }
}
</script>
