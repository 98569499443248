import axios from 'axios';

const apiBaseUrl = process.env.VUE_APP_API_SERVER
console.log(apiBaseUrl)
const config = {
  baseURL: apiBaseUrl
};

const httpClient = axios.create(config);

const authInterceptor = config => {
  const token = localStorage.getItem("token") || null;
  const pc = localStorage.getItem("pc") || null;
  const referral_code = localStorage.getItem("referral_code") || null;
  const promocode = localStorage.getItem('promocode') || null;
  if (token){
    config.headers.Authorization = "Token " + token;
  }
  else{
    config.headers.Authorization = null
  }
  if (pc){
    config.headers.PC = pc;
  }
  else{
    config.headers.PC = null
  }
  if (promocode){
    config.headers.promocode = promocode;
  }
  else{
    config.headers.promocode = null
  }
  if (referral_code){
    config.headers.referral_code = referral_code;
  }
  else{
    
    config.headers.referral_code = null
  }
  return config;
};

const loggerInterceptor = config => {
  return config;
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export { httpClient };