<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import EmptyLayout from "../src/layout/EmptyLayout";
import MainLayout from "../src/layout//MainLayout";
import ServiceLayout from "../src/layout/ServiceLayout";

import { httpClient } from "./HttpClientV1/HttpClient";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },

    components: {
      EmptyLayout,
      MainLayout,
      ServiceLayout
    },
    mounted() {
      if(this.$route.path != '/user/activate'){
      if (this.$store.getters.isLoggedIn) {
        this.checkConfirm();
      }
    }
    
  },
  methods: {
    checkConfirm(){
      httpClient
        .get("api/users/check_confirm")
        .then((resp) => {
          if(resp.data.status == 'error'){
            this.$router.push('/user/email/confirm')
          }
        });
    },
  }
};
</script>
<style>

</style>
